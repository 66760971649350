@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  outline: none;
}

html {
  height: 100%;
  overflow-x: hidden !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.4;
}
body {
  width: 100%;
  height: 100%;
  font-family: "Work Sans", sans-serif;
  color: var(--main-font);
  &::-webkit-scrollbar {
    width: 10px;
    @media (max-width: 1475px) {
      width: 9px;
    }
  }

  &::-webkit-scrollbar-track {
    background: #e2e2e2;
    border-radius: 1px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #474c55;
    border-radius: 1px;
  }
}

p {
  line-height: 1.7;
}

.rec-swipable {
  align-items: stretch;
}
.rec-carousel-item-visible {
  margin: 0;
  display: flex;
}

button {
  cursor: pointer;
}

.bg {
  position: absolute;
  left: 0%;
  top: 0%;
  bottom: 0%;
  right: 0%;
  z-index: 1;
}

.styledButton {
  position: relative;
  white-space: nowrap;
  background-color: transparent;
  height: 10rem;
  width: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--main-font);
  overflow: hidden;
  border: 3px solid var(--main-font);
  @media (max-width: 1100px) {
    height: 7.5rem;
    width: 7.5rem;
  }

  p {
    font-size: max(18px, 0.9vw);
    font-weight: 700;
    z-index: 4;
    color: #fff !important;
    white-space: nowrap;
    position: relative;
  }

  .icon {
    font-size: max(20px, 1vw);
    z-index: 4;
    transition: ease-in-out 0.2s;
    color: #fff !important;
    white-space: nowrap;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    background-color: var(--main-font);
    transition: ease-in-out 0.5s;
    color: #fff;
    transform: translateY(0);
    opacity: 1;
    // border-radius: 50%;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    background-color: var(--main-font);
    transition: ease-in-out 0.5s;
    color: #fff;
    transform: translateY(0);
    opacity: 1;
    // border-radius: 50%;
  }
  &:hover {
    &::before {
      transform: translateY(-120%);
      opacity: 0.5;
    }

    &::after {
      transform: translateY(120%);
      opacity: 0.5;
    }

    p {
      color: var(--main-font) !important;
    }

    .icon {
      color: var(--main-font) !important;
    }
  }
}

.styledButton2 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: max(0.35rem, 0.5vw) max(0.5rem, 2vw);
  border-radius: max(30px, 3vw);
  background-color: transparent;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: ease-in-out 0.3s;
  border: 2px solid var(--second-color);

  @media (max-width: 900px) {
    padding: 0.5rem 1rem;
  }

  p {
    font-size: max(13.5px, 0.9vw);
    z-index: 4;
    transition: ease-in-out 0.2s;
    color: #fff !important;
    white-space: nowrap;
    position: relative;
  }

  svg {
    font-size: max(20px, 1vw);
    z-index: 4;
    transition: ease-in-out 0.2s;
    color: #fff !important;
    white-space: nowrap;
    position: relative;
  }

  &::before {
    content: "";
    border-radius: max(10px, 0.5vw);
    position: absolute;
    width: 100%;
    z-index: 1;
    height: 100%;
    top: 0%;
    left: 0%;
    bottom: 0%;
    right: 0%;
    background-color: var(--second-color);
    transition: ease-in-out 0.3s;
    color: #fff;
    transform: scale(1);
  }
  &:hover {
    &::before {
      transform: scale(0);
      opacity: 0.2;
    }

    p {
      color: var(--second-color) !important;
    }

    svg {
      color: var(--second-color) !important;
    }
  }
}

.unstyledButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: max(0.35rem, 0.5vw) max(0.5rem, 2vw);
  border-radius: max(10px, 0.5vw);
  background-color: transparent;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: ease-in-out 0.3s;
  border: none;
  align-items: center;
  gap: max(0.25rem, 0.5vw);

  @media (max-width: 900px) {
    padding: 0.5rem 1rem;
  }

  p {
    font-size: max(13.5px, 0.9vw);
    z-index: 4;
    transition: ease-in-out 0.2s;
    color: #fff !important;
    white-space: nowrap;
    position: relative;
  }

  svg {
    font-size: max(20px, 1.5vw);
    z-index: 4;
    transition: ease-in-out 0.2s;
    color: #fff !important;
    white-space: nowrap;
    position: relative;
  }
}

.borderBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: max(0.35rem, 0.5vw) max(0.5rem, 2vw);
  border-radius: max(10px, 0.5vw);
  background: transparent;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: ease-in-out 0.4s;
  border: 1px solid var(--main-color);

  @media (max-width: 900px) {
    padding: 0.5rem 1rem;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    background-color: var(--main-color);
    transform: scale(0);
    border-radius: max(10px, 0.5vw);
    transition: ease-in 0.25s;
  }

  p {
    font-size: max(13.5px, 0.9vw);
    z-index: 2;
    color: var(--main-font);
    white-space: nowrap;
  }

  .icon {
    color: var(--main-color);
    font-size: max(20px, 1.5vw);
  }

  &:hover {
    border: 1px solid var(--main-color);

    &::after {
      transform: scale(1.1);
    }

    p {
      color: #fff !important;
    }

    .icon {
      color: #fff !important;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-transition: background-color 5000s ease-in-out 0s;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

.big-title {
  font-size: max(30px, 3.5vw);
}

.centered-text {
  text-align: center;
}
.mid-title {
  font-size: max(22px, 2.5vw);
}
.small-title {
  font-size: max(19px, 1.75vw);
}
.exsmall-title {
  font-size: max(14px, 1.5vw);
}
span.color {
  color: var(--main-color);
}

span.text-border {
  color: var(--reverse-font);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--main-font);
}

.text {
  display: flex;
  flex-direction: column;
  gap: max(0.5rem, 1vw);
  align-items: flex-start;
}

.uppercase {
  text-transform: uppercase;
}
.shared-h5 {
  font-size: max(15px, 0.9vw);
  color: var(--main-color);
  font-weight: 500;
}

.def-text {
  font-size: max(15px, 1.2vw);
}

.small-text {
  font-size: max(12px, 0.75vw);
}

.centered-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.hover-button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: fit-content;
  background: var(--main-color);
  color: #fff;
  font-size: max(15px, 0.9vw);
  padding: max(12px, 0.7vw) max(22.5px, 2vw);
  border-radius: max(25px, 2vw);
  min-width: max(7vw, 110px);
  &:hover {
    animation: pulse-white 0.7s ease-in-out;
    -webkit-animation: pulse-white 0.7s ease-in-out;
  }
}
.hover-button2 {
  display: flex;
  align-items: center;
  color: var(--main-color);
  font-size: max(15px, 0.9vw);
  margin: max(7.5px, 0.5vw) 0;
  svg {
    margin-left: max(7px, 0.5vw);
  }
  &:hover svg {
    animation: hvr-icon-wobble-horizontal 1s ease-in-out 1;
    -webkit-animation: hvr-icon-wobble-horizontal 1s ease-in-out 1;
  }
}
.rec-arrow,
.rec-arrow-left,
.rec-arrow-right {
  border: 1px solid transparent !important;
  box-shadow: rgba(161, 161, 161, 0.16) 0px 1px 4px !important;
  border-radius: 50% !important;
  background: transparent !important;
  width: max(30px, 2.75vw) !important;
  height: max(30px, 2.75vw) !important;
  min-width: max(30px, 2.75vw) !important;
  min-height: max(30px, 2.75vw) !important;
  max-width: max(30px, 2.75vw) !important;
  max-height: max(30px, 2.2vw) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: max(15px, 1vw) !important;
  color: var(--main-color) !important;
  position: relative;
  &:hover {
    color: #fff !important;
    background: var(--main-color) !important;
    box-shadow: rgba(161, 161, 161, 0.25) 0px 5px 10px !important;
  }
}

.rec-dot {
  width: max(20px, 1.5vw) !important;
  height: max(11px, 0.7vw) !important;
  margin: max(5px, 0.35vw) !important;
  border: 0 !important;
  box-shadow: none !important;
  background: var(--jobs-icon) !important;
  border-radius: max(10px, 1vw) !important;

  &:hover,
  &:active,
  &:focus {
    box-shadow: none !important;
    background: rgba(var(--main-rgb), 1) !important;
  }
  &:active,
  &:focus {
    box-shadow: 0 0 0 max(3px, 0.25vw) rgba(var(--main-rgb), 0.2) !important;
  }
}
.rec-dot_active {
  background: var(--main-color) !important;
  box-shadow: 0 0 0 max(3px, 0.25vw) rgba(var(--main-rgb), 0.2) !important;
}

@keyframes pulse-white {
  0% {
    box-shadow: 0 0 0 0 rgba(var(--main-color), 0.5);
  }
  70% {
    box-shadow: 0 0 0 1.2rem rgba(128, 173, 217, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(128, 173, 217, 0);
  }
}
@-webkit-keyframes pulse-white {
  0% {
    box-shadow: 0 0 0 0 rgba(var(--main-color), 0.5);
  }
  70% {
    box-shadow: 0 0 0 1.2rem rgba(128, 173, 217, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(128, 173, 217, 0);
  }
}

//Animation ----------------------------------------------------
@-webkit-keyframes hvr-icon-wobble-horizontal {
  16.65% {
    -webkit-transform: translateX(6px);
    transform: translateX(6px);
  }
  33.3% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes hvr-icon-wobble-horizontal {
  16.65% {
    -webkit-transform: translateX(6px);
    transform: translateX(6px);
  }
  33.3% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
//--------------------------------------
@-webkit-keyframes hvr-icon-down {
  0%,
  50%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  25%,
  75% {
    -webkit-transform: translateY(max(7.5px, 0.75vw));
    transform: translateY(max(7.5px, 0.75vw));
  }
}
@keyframes hvr-icon-down {
  0%,
  50%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  25%,
  75% {
    -webkit-transform: translateY(max(7.5px, 0.75vw));
    transform: translateY(max(7.5px, 0.75vw));
  }
}

@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.51;
  }
  25% {
    opacity: 0.35;
  }
  50% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0.25;
  }
  75% {
    opacity: 0.15;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.05;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.51;
  }
  25% {
    opacity: 0.35;
  }
  50% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0.25;
  }
  75% {
    opacity: 0.15;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.05;
  }
}

@-webkit-keyframes scale-in-center2 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.41;
  }
  25% {
    opacity: 0.35;
  }
  50% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0.25;
  }
  75% {
    opacity: 0.15;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.05;
  }
}
@keyframes scale-in-center2 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.41;
  }
  25% {
    opacity: 0.35;
  }
  50% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0.25;
  }
  75% {
    opacity: 0.15;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.05;
  }
}

@-webkit-keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

//color switch and dark/light mode -------------------------------------------
:root {
  --main-color: #4b6791;
  --dark-color: #0b0d13;
  --main-font: #000;
  --reverse-font: #fff;
  --main-comp: #fff;
  --main-rgb: (73, 153, 138);
  --second-rgb: (220, 140, 66);
  --main-black: #000;
  --main-bg: #fff;
  --dark-body: #fff;
  --main-border: #cfcfcf;
  --main-darkGray: #717171;
  --main-lightGray: #999999;
  --dark-comp: #fff;
}

body.dark-mode {
  --main-font: #fff;
  --reverse-font: #000;
  --main-comp: #001c18;
  background: #01100e;
  --main-bg: #01100e;
  --dark-body: #1a171d;
  --dark-border: #6d9af3;
  --dark-comp: #001c18;
  --main-black: #fff;
  --main-border: #002c25;
  --main-reverse: #000;
  --mm-icon: #ffff;
}

//media queries--------------------------------------------------
@media (max-width: 768px) {
  .shared-text,
  .shared-h5,
  .hover-button2 {
    font-size: 16px;
  }
}
